const routes = {
  path: "/audios",
  name: "Audios",
  component: () => import("./Module.vue"),
  meta: { requiresAuth: true },
  children: [
    {
      path: 'gana',
      name: 'AudiosGana',
      component: () => import("./views/Gana.vue"),
    }
  ]
};

export default routes;
