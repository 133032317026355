import Vue from 'vue'
import VueI18n from 'vue-i18n'
import es from '@/locales/es'
import pt from '@/locales/pt'

const i18n = () => new VueI18n({
	locale: 'es',
	messages: {
		es, pt
	}
})

Vue.use(VueI18n)

export default i18n