const routes = {
  path: "/chat",
  name: 'Chat',
  component: () => import("./views/Chat.vue"),
  meta: { requiresAuth: true },
  // children : [
  //   {
  //     path : ':id',
  //     name: "Ticket",
  //     props: (route) => Object.assign({}, route.query, route.params),
  //     component: () => import("./views/Ticket.vue"),
  //   },
  // ]
};

export default routes;
