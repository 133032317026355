import axios from "axios";

function urlB64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i)
    outputArray[i] = rawData.charCodeAt(i);

  return outputArray;
}

function decode64(data) {
  return new TextDecoder().decode(urlB64ToUint8Array(data));
}

const auth = {
  state: () => ({
    token: localStorage.getItem("token") || null,
    permisos: JSON.parse(localStorage.getItem("permisos")) || null,
    refreshLock: Date.now(),
    tenant: JSON.parse(localStorage.getItem("tenant")) || null,
    estados: JSON.parse(localStorage.getItem("estados")) || null,
  }),
  getters: {
    isLoggedIn: (state) => !!state.token,
    getToken: (state) => state.token,
    getTokenPayload: (state) =>
      state.token ? JSON.parse(decode64(state.token.split(".")[1])) : null,
    getTenant: (state) => state.tenant || {},
    getPermisos: (state) => state.permisos,
    getEstados: (state) => state.estados,
  },
  mutations: {
    logout(state) {
      return new Promise((resolve) => {
        localStorage.removeItem("token");
        localStorage.removeItem("permisos");
        state.token = null;
        resolve();
      });
    },
    login(state, token) {
      localStorage.setItem("token", token);
      state.token = token;
    },
    setEstados(state, estados) {
      return new Promise((resolve) => {
        localStorage.setItem("estados", JSON.stringify(estados));
        state.estados = estados;
        resolve();
      })
    },
    setTenant(state, tenant) {
      return new Promise((resolve) => {
        localStorage.setItem( "tenant", JSON.stringify(tenant) )
        state.tenant = tenant;
        resolve();
      });
    },
    setPermisos(state, per) {
      return new Promise(async (resolve) => {
        localStorage.setItem("permisos", JSON.stringify(per));
        state.permisos = per;
        resolve();
      });
    },
  },
  actions: {
    login({ commit, getters }, { user, password }) {
      return new Promise(async (resolve, reject) => {
        try {
          const formData = new FormData();
          formData.append("username", user);
          formData.append("password", password);
          const { data: token } = await axios({
            method: "POST",
            url: `${process.env.VUE_APP_API_URL}/auth/login`,
            data: formData,
            headers: {
              tenant: getters.getTenant.db
            }
          });

          axios = axios.create({
            headers: {
              Authorization: `${token.token_type} ${token.access_token}`,
              tenant: getters.getTenant.db
            },
            baseURL : `${process.env.VUE_APP_API_URL}/${getters.getTenant.slug}`
          });


          await commit("login", token.access_token);
          resolve();
        } catch (e) {
          console.error(e);
          reject();
        }
      });
    },
    refreshToken({ commit, getters, state }) {
      return new Promise(async (resolve) => {
        if (Date.now() < state.refreshLock || !getters.getToken) {
          resolve();
          return;
        }

        const { data: token } = await axios({
          method: "GET",
          url: `/auth/refresh_token`,
          headers: { Authorization: `bearer ${getters.getToken}` },
        });

        state.refreshLock = Date.now() + 1000 * 60 * 10;

        resolve(
          await Promise.all([
            commit("login", token.access_token),
          ])
        );
      });
    },
    logout({ commit }) {
      return new Promise(async (resolve) => {
        commit("logout");
        resolve();
      });
    },
    setTenant({ commit }, tenant) {
      return new Promise(async (resolve) => {
        await commit("setTenant", tenant);
        resolve();
      });
    },
    setPermisos({ commit }, permisos) {
      return new Promise(async (resolve) => {
        await commit("setPermisos", permisos);
        resolve();
      });
    },
  },
};

export default auth;
