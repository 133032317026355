const routes = {
  path: "/borradores",
  component: () => import("./Module.vue"),
  meta: { requiresAuth: true },
  children : [
    {
      path : '',
      name: "Borradores",
      component: () => import("./views/Borradores.vue"),
    },
    {
      path : 'nuevo',
      name: "NuevoBorrador",
      props: route => Object.assign({}, route.params, route.query),
      component: () => import("./views/borrador-router.vue"),
    },
    // {
    //   path : 'nuevo1774',
    //   name: "NuevoBorrador1774",
    //   meta: {
    //     titulo: 'Enviar borrador',
    //   },
    //   props: route => Object.assign({}, route.params, route.query),
    //   component: import("./views/Borradores1774.vue"),
    // },
    // {
    //   path : 'nuevoZirconite',
    //   name: "NuevoBorradorZirconite",
    //   meta: {
    //     titulo: 'Enviar borrador',
    //   },
    //   props: route => Object.assign({}, route.params, route.query),
    //   component: () => import("./views/BorradoresZirconite.vue"),
    // },
    // {
    //   path : 'nuevoJaeva',
    //   name: "NuevoBorradorJaeva",
    //   meta: {
    //     titulo: 'Enviar borrador',
    //   },
    //   props: route => Object.assign({}, route.params, route.query),
    //   component: () => import("./views/BorradoresJaeva.vue"),
    // },
    {
      path : ':id',
      name: "EditarBorrador",
      props: route => Object.assign({}, route.params, route.query),
      component: () => import("./views/borrador-router.vue"),
    },
  ]
};

export default routes;
