import 'core-js/stable'
import 'regenerator-runtime/runtime'


import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

import axios from "axios";

if ( store.getters.getToken ) {

  window.axios = axios.create({
    headers: { Authorization: `bearer ${store.getters.getToken}`, tenant : store.getters?.getTenant?.db },
    baseURL : `${process.env.VUE_APP_API_URL}/${ store.getters?.getTenant?.slug }`
  });

} else {

  window.axios = axios.create({
    headers: { tenant : store.getters?.getTenant?.db },
    baseURL : `${process.env.VUE_APP_API_URL}/${ store.getters?.getTenant?.slug }`
  });

}

import meta from 'vue-meta'
Vue.use(meta)

import portal from 'portal-vue'
Vue.use(portal)

import VueHotkey from 'v-hotkey'
Vue.use(VueHotkey)

Vue.filter('eur', function (value) {
  return parseFloat(value).toFixed(2) + ' €';
});

import {parseDate} from '@/utils/index.js'
import './registerServiceWorker'
Vue.filter('date', function (value) {
  return parseDate(value);
});

import i18n from '@/plugins/i18n.js'

new Vue({
  router,
  store,
  vuetify,
  i18n: i18n(),
  render: (h) => h(App),
  methods: {
    modules(mod) {
      return store.getters.getTenant && store.getters.getTenant.modules && store.getters.getTenant.modules.includes( mod )
    },
    acceso(permiso) {
      if (!store.getters.getTokenPayload || !store.getters.getTokenPayload.permisos) {
        store.commit('logout')
        return false
      }
      return !!store.getters.getTokenPayload.permisos[permiso]
    }
  },
}).$mount("#app");
