import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store/index.js";
// import auth from '../modules/auth/store'

Vue.use(VueRouter)

import home from '@/modules/home'
import auth from '@/modules/auth'
import contratos from '@/modules/contratos'
import facturas from '@/modules/facturas'
import borradores from '@/modules/borradores'
import descargas from '@/modules/descargas'
import tickets from '@/modules/tickets'
import audios from '@/modules/audios'
import leads from '@/modules/leads'

const modules = (mod) => store.getters.getTenant && store.getters.getTenant.modules

const routes = [
  home.router,
  auth.router,
  // modules('CONTRATOS') ? contratos.router : null,
  // modules('FACTURAS') ? facturas.router : null,
  // modules('BORRADORES') ? borradores.router : null,
  contratos.router,
  facturas.router,
  borradores.router,
  descargas.router,
  tickets.router,
  audios.router,
  leads.router,
  {
    path: '*',
    redirect : '/'
  }
].filter(x => x !== null)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const validarToken = (token) => token && new Date((token.exp * 1000)).getTime() > new Date().getTime();

router.beforeEach(async (to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requiresAuth)
    && !validarToken(store.getters.getTokenPayload)
  ) {
    let msg = store.getters.getToken != null ? "La sesión ha expirado" : "";
    await store.dispatch("logout");
    next({ name: "Login", query: { redirect: to.path, msg } });
  }

  // if (to.matched.some((record) => record.meta.acceso)) {
  //   const acceso = to.matched.find((record) => record.meta.acceso).meta.acceso;
  //   if (
  //     !!(
  //       store.getters.getDecodedJwtEmpresa.permisos_b &
  //       store.getters.getPermisos[acceso]
  //     )
  //   )
  //     next();
  //   else next({ name: "Home" });
  // }
  next();
});

export default router
