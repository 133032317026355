export function encode64(data) {
  return btoa(data);
}
export function decode64(data) {
  return new TextDecoder().decode(urlB64ToUint8Array(data));
}

function urlB64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i)
    outputArray[i] = rawData.charCodeAt(i);

  return outputArray;
}

export function humanFileSize(bytes, dp = 1, units = "file") {
  const thresh = 1000;

  let unitArray;
  switch (units) {
    case "file":
      unitArray = ["b", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      break;
    case "length":
      unitArray = ["mm", "m", "km"];
      break;
    case "area":
      unitArray = ["m2", "km2"];
      break;
  }

	if (Math.abs(bytes) < thresh) {
    return bytes + unitArray[0];
  }

  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < unitArray.length - 1
  );

  return bytes.toFixed(dp) + " " + unitArray[u];
}

export function parseDate(date, timestamp = false, mes = true, hora = false) {
  if (date == null || date == undefined || date == "") return "";
  let fecha;

  if (!isNaN(Number(date))) fecha = new Date(Number(date));
  else
    fecha = new Date(
      String(date)
        .replaceAll(/\\/g, "")
        .split(" ")[0]
    );

  if (timestamp) {
    fecha.setHours(0, 0, 0, 0);
    return fecha.getTime();
  }

  const MESES = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  let day = `0${fecha.getDate()}`.slice(-2);
  let month = mes
    ? MESES[fecha.getMonth()]
    : `0${fecha.getMonth() + 1}`.slice(-2);
  let year = fecha.getFullYear();

  if (hora)
    var HORA = [
      ("00" + fecha.getHours()).slice(-2),
      ("00" + fecha.getMinutes()).slice(-2),
    ].join(":");

  return [[day, month, year].join(" "), HORA].filter((v) => !!v).join(", ");
}

// const status = {
//   ...store.getters.getEstados
// };

// export function getSituacionIcon(situacion) {
//   return status[situacion] || { color: "grey", icon: "mdi-circle" };
// }

export function downloadFile(file, open = false) {
  let a = document.createElement("a");
  a.href = URL.createObjectURL(file);
  if (
    open &&
    ![
      "iPad",
      "iPhone",
      "iPod",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad Simulator",
      "Macintosh",
      "MacIntel",
      "MacPPC",
      "Mac68K",
      "Pike v7.6 release 92",
      "Pike v7.8 release 517",
    ].includes(navigator.platform)
  )
    a.target = "_blank";
  else a.download = file.name;
  a.click();
}

export const perColumnFilter = (value, filter, dataType = "text") => {
  // console.log('FUNCION FILTRO', {filter, value})

  if (
    filter === "" ||
    filter === null ||
    filter === undefined ||
    (Array.isArray(filter) && filter.length == 0)
  )
    return true;
  if (filter === "-") return value === null;
  if (value === "" || value === null || value === undefined) return false;

  const filterText = (filter, value) => {
    value = String(value)
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    filter = String(filter)
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    const isOperation = /=|<|>|!/.test(filter[0]);
    if (isOperation) {
      switch (filter[0]) {
        case "!":
          return !value.includes(filter.substring(1));
        case "=":
          return value == filter.substring(1);
        case ">":
          return Number(value) >= Number(filter.substring(1));
        case "<":
          return Number(value) <= Number(filter.substring(1));
        default:
          return false;
      }
    } else {
      return value.includes(filter);
    }
  };
  const filterDate = (filter, value) => {
    filter = filter.map((f) => {
      let temp = new Date(f);
      temp.setHours(0, 0, 0, 0);
      return temp.getTime();
    });
    value = parseDate(value, true);
    let max = Math.max(...filter);
    let min = Math.min(...filter);
    return max >= value && value >= min;
  };
  const filterSelect = (filter, value) => {
    if (filter.length > 1) return filter.includes(value);
    else return filterText("=" + filter[0], value);
  };
  const filterBool = (filter, value) => filter == value

  switch (dataType) {
    case "select":
      return filterSelect(filter, value);
    case "date":
      return filterDate(filter, value);
    case "bool":
      return filterBool(filter, value);
    case "text":
    default:
      return filterText(filter, value);
  }
};

export function timeAgo(timestamp, hours = true) {
  // if (timestamp == null) return null;
	let now = Date.now();
  if (!hours) {
    let dateAux = new Date(timestamp);
    dateAux.setHours(0, 0, 0, 0);
    timestamp = dateAux.getTime();
		dateAux = new Date();
    dateAux.setHours(0, 0, 0, 0);
    now = dateAux.getTime();
  }
  const future = timestamp > now;
  const DATE_UNITS = {
    // week: 86400 * 7,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
  };

  const getUnitAndValueDate = (secondsElapsed) => {
    secondsElapsed = Math.abs(secondsElapsed);
    for (const [unit, secondsInUnit] of Object.entries(DATE_UNITS)) {
      if (secondsElapsed >= secondsInUnit || unit === "second") {
        const value = Math.floor(secondsElapsed / secondsInUnit) * -1;
        return { value, unit };
      }
    }
  };

  const getTimeAgo = () => {
    const rtf = new Intl.RelativeTimeFormat("es", {
      localeMatcher: 'best fit',
      numeric: 'auto',
    });

    const { value, unit } = getUnitAndValueDate(secondsElapsed);
    return rtf.format(future ? Math.abs(value) : Math.abs(value) * -1, unit);
  };

  const secondsElapsed = (now - timestamp) / 1000;
  // const secondsElapsed = Date.now() > timestamp ? (Date.now() - timestamp) / 1000 : (timestamp - Date.now()) / 1000

  if (secondsElapsed >= DATE_UNITS.week) {
    return parseDate(timestamp, false, true, true);
  } else {
    const [first, ...rest] = getTimeAgo(timestamp);
    return [first.toUpperCase(), rest.join("")].join("");
  }
}

export function jsonToCsv(items, csvHeaders, filename) {
  const JStoExcelDate = (date) => {
    const [day, month, year] = date.split(' ')
    return [year, month, day].join('-');
  }

  const csvString = [
    ...items.map(item => {
      let temp = [];
      csvHeaders.forEach(({ value : key, dataType }) => {
        let valor = (item[key] != null ? String(item[key]) : item[key] || '\u0020').replace('\r\n', '');
        if (dataType == 'date') valor = JStoExcelDate(parseDate(valor, false, false));
        else if (dataType == 'number') valor = valor.replace('.', ',');
        else if (dataType == 'html') valor = valor.replaceAll(/<[^>]*>/g, '').replaceAll(/;/g, '');
        temp.push(valor)
      })
      return temp;
    })
  ];

  const csvFile = new File(['\ufeff', [csvHeaders.map(h => h.alias || h.value), ...csvString].map(e => e.join(";")).join("\n")], `${filename}.csv`, { type: 'text/csv;charset=utf-8;' });

  downloadFile(csvFile);
}

export async function copyText(text) {
  return await navigator.clipboard.writeText(text)
}

export function getTicketStatus(state) {
	// state = state.toLowerCase() || "";
	switch (state) {
		case "abierto":
			return { color: "primary", icon: "mdi-lock-open-variant" };
		case "cerrado":
			return { color: "error", icon: "mdi-lock" };
		default:
			return { color: "error", icon: "" };
	}
}