export default {
  common: {
    dragAndDrop: "Clique aqui ou arraste arquivos",
    aceptar: "Aceitar",
    cancelar: "Cancelar",
    ok: "Ok",
    tipo: "Tipo",
    nombre: "Nome",
    recargar: "Recarregar",
    editar: "Editar",
    volver: "Voltar",
    guardar: "Salvar",
    subir: "Subir",
    validar: "Validar campos",
    eliminar: "Excluir",
    cups: "CUPS",
    exportar: "Exportar",
    cargados: "Carregados {n} de {t}",
    cargar_mas: "Carregar mais",
    descargar: "Baixar",
    busqueda: "Busca...",
    exportar: "Exportar",
    ver: "Ver",
  },
  audios_gana: {
    venta: "Áudio de Venda",
    legal: "Texto Legal",
    certificado: "Certificado",
    subir_todo: "Subir tudo",
    nif_no_detectado: "NIF não detectado nos seguintes arquivos:",
    subida_ok: "Os arquivos foram carregados com sucesso",
  },
  confirm: {
    eliminar: {
      archivo: {
        title: "Excluir arquivo",
        text: "Tem certeza de que deseja excluir este documento?",
      },
      borrador: {
        title: "Excluir rascunho",
        text: "Tem certeza de que deseja excluir este rascunho? Esta ação não pode ser desfeita",
        ok_result: "Excluído com sucesso",
      },
      mensaje: {
        title: "Excluir mensagem",
        text: "Tem certeza de que deseja excluir esta mensagem? Esta ação não pode ser desfeita",
        ok_result: "Excluído com sucesso",
      },
    },
    revision: {
      title: "Enviar para revisão",
      text: "Tem certeza de que deseja enviar este rascunho para revisão? Uma vez enviado, você não poderá modificar os dados",
      ok_result: "Salvo como contrato | Salvo como rascunho",
      error_result:
        "Erro ao salvar como contrato | Erro ao salvar como rascunho",
    },
    anular: {
      title: "Anular contrato",
      text: "Tem certeza de que deseja anular este contrato?",
      ok_result: "Contrato anulado com sucesso",
      error_result: "Erro ao anular o contrato",
      motivo: "Motivo anulação",
    }
  },
  login: {
    error: "Os dados de acesso estão incorretos",
    usuario: "Usuário",
    pass: "Senha",
    login: "Acessar",
  },
  noticias: {
    novedades: "Novidades",
    mas_menos: "Ler mais | Ler menos",
  },
  menu: {
    inicio: "Início",
    contratos: "Contratos",
    borradores: "Rascunhos",
    documentos: "Documentos",
    facturas: "Faturas",
    verificaciones: "Verificações",
    llamadas: "Ligações",
    audios_gana: "Áudios Gana",
    leads: "Leads",
    comparativas: "Comparativos",
    chat: "Chat",
    tarjetas: {
      inicio: "@:menu.inicio",
      contratos:
        "Você tem {n} contratos parados | Você tem 1 contrato parado | Você não tem contratos parados",
      borradores:
        "Você tem {n} rascunhos para enviar | Você tem 1 rascunho para enviar | Você não tem rascunhos para enviar",
      facturas:
        "Você tem {n} faturas pendentes | Você tem 1 fatura pendente | Você não tem faturas pendentes",
      verificaciones:
        "Você tem {n} verificações para subir | Você tem 1 verificação para subir | Você não tem verificações para subir",
      documentos: "Documentos disponíveis para descarregar",
      leads: "{0} registrados",
      comparativas: "Faça comparativos de luz ou gás a partir do CUPS",
      chat: "Você tem {0} mensagens não lidas",
    },
  },
  borradores: {
    nuevo: "Novo rascunho",
    fecha: "Data",
    cups: "@:common.cups",
    comer: "Comercializadora",
    tipo_contrato: "Tipo de contrato",
    llamadas: "Ligações",
    tarifa: "Tarifa",
    potencia: "Potência {p}",
    consumo: "Consumo",
    oferta: "Oferta",
    dni_titular: "DNI/NIF titular",
    nombre_titular: "Nome do titular",
    dni_firmante: "DNI/NIF do assinante",
    nombre_firmante: "Nome do assinante",
    cp_cups: "@:borradores.cp @:common.cups",
    pob_cups: "Cidade @:common.cups",
    dir_cups: "Endereço @:common.cups",
    prov_cups: "Província @:common.cups",
    telf: "Telefone",
    email: "Email",
    iban: "Conta bancária",
    otros: "Outros dados",
    cups2: "@:common.cups 2",
    refcomer: "REF.COMER",
    refcomer2: "REF.COMER2",
    cnae: "CNAE",
    cambiotit: "Mud.Tit",
    cambiopot: "Mud.Pot",
    fecha_act: "Data de ativação",
    dir_corres: "Endereço de correspondência",
    cp: "Cód.Postal",
    cp_corres: "Cód.Postal de correspondência",
    per_contacto: "Pessoa de contato",
    telf2: "@:borradores.telf 2",
    prod_adicionales: "Produtos adicionais",
    documentacion: "Documentação",
    adjunta_doc: "Anexe aqui a documentação do contrato",
    validacion_ok: "Campos validados com sucesso",
    cups_o_titular:
      "É obrigatório um CUPS ou um titular para salvar o rascunho",
    intervalo: "Intervalo",
    factura_electronica: "Fatura eletrônica",
    pago_domiciliado: "Pagamento de residente",
  },
  documentacion: {
    not_found: "Documento não encontrado",
    error_subir: "Não foi possível carregar o documento",
  },
  verificacion: {
    adjuntar: "Anexar verificação",
    not_found: "Documento não encontrado",
    error_subir: "Não foi possível enviar o documento",
  },
  contratos: {
    estado: "Estado do contrato",
    motivo_dev: "MOTIVO DE DEVOLUÇÃO",
    motivo_anu: "MOTIVO DE ANULAÇÃO",
    motivo_det: "MOTIVO DE DETENÇÃO",
    datos: "Dados do contrato",
    factura: "Fatura de pagamento",
    fecha: "Data",
    bimp: "B.Imp.",
    ver_doc: "Ver documento",
    ver_fac: "Ver fatura",
    anular: "Anular contrato",
    motivo_anulacion: "Motivo anulação",
    comentario: "Comentário",
    agente: "Agente",
    tipo: "Tipo",
    nombre: "Nome",
    documentos: "Documentos anexados",
    historico: "Histórico do contrato",
  },
  descargas: {
    vacio: "Não há arquivos disponíveis para download",
    comers: "TODAS AS COMERCIALIZADORAS",
  },
  facturas: {
    estado: "Estado da fatura",
    pago_fac: "Pagamento da fatura",
    cuenta_pago: "Conta de pagamento",
    ver_doc: "Ver documento",
    desglose: "Desdobramento da fatura",
    datos: "Dados da fatura",
    razon_social: "Razão social",
    nif: "NIF/CIF",
    fecha_fac: "Data da Fatura",
    n_doc: "Número do documento",
    bimp: "Base Imponível",
    iva: "IVA {pct}%",
    irpf: "IRPF {pct}%",
    total: "Total da Fatura",
    dir: "Endereço",
    pob: "Localidade",
    prov: "Província",
    cp: "Cód.Postal",
    contenido: "Conteúdo da fatura",
    lineas: "1 linha | {n} linhas",
    hist: "Histórico da fatura",
    ver_contrato: "Abrir o contrato",
    adjuntar: "Anexar documento",
    fechafactura: "Data da fatura",
    numerofactura: "Nº da fatura",
    bifactura: "Base Imp.",
    totalfactura: "Total da fatura",
    estadofactura: "Estado da fatura",
  },
  chat: {
    archivos_adjuntos: "1 arquivo anexado | {n} arquivos anexados",
    escribe_aqui: "Escreva aqui sua mensagem...",
    adjuntar_archivos: "Anexar arquivos",
  },
};
