<template>
	<!-- <v-banner v-if="deferredPrompt" color="primary" dark class="text-left">
		Get our free app. It won't take up space on your phone and also works
		offline!
		<template v-slot:actions>
			<v-btn text @click="dismiss">Dismiss</v-btn>
			<v-btn text @click="install">Install</v-btn>
		</template>
	</v-banner> -->
</template>

<script>
export default {
	name: "App",
	data() {
		return {
			deferredPrompt: null
		};
	},
	created() {
		window.addEventListener("beforeinstallprompt", e => {
			e.preventDefault();
			// Stash the event so it can be triggered later.
			// if (Cookies.get("add-to-home-screen") === undefined) {
			this.deferredPrompt = e;
			// }
		});
		window.addEventListener("appinstalled", () => {
			this.deferredPrompt = null;
		});
		document.querySelector('link[rel=manifest]').setAttribute('href', this.$store.getters.getTenant.manifest);
	},
	methods: {
		async dismiss() {
			// Cookies.set("add-to-home-screen", null, { expires: 15 });
			this.deferredPrompt = null;
		},
		async install() {
			this.deferredPrompt.prompt();
		}
	}
};
</script>