<template>
	<v-bottom-navigation background-color="app" v-bind="$attrs" app class="elevation-0" shift :value="link">
		<!-- style="overflow-x: auto; overflow-y: hidden; display: flex; justify-content: start;" -->
		<!-- <v-btn v-for="m in menu" :key="m.link" :to="m.link" v-on="m.events || {}"
				style="max-width: calc(100vw / 5); flex: unset; max-width: unset; ">
				<v-icon>{{ m.icon }}</v-icon>
				<span>{{ m.title }}</span>
			</v-btn> -->
		<v-btn :value="m.link" style="min-width: unset;" class="flex-grow-1" v-for="m in menu" :key="m.link" :to="m.link"
			v-on="m.events || {}">
			<v-badge dot overlap class="v-icon" :content="m.alert" :value="m.alert">
				<v-icon color="white">{{ link == m.link ? m.icon : m.icon_inactive || m.icon }}</v-icon>
			</v-badge>
			<span class="white--text">{{ m.title }}</span>
		</v-btn>
	</v-bottom-navigation>
</template>

<script setup>
import { getCurrentInstance, computed, ref, watch } from 'vue';

const props = defineProps({
	link: {
		type: String,
	}
})

const store = getCurrentInstance().proxy.$store
const route = getCurrentInstance().proxy.$route

const menu = computed(() => store.getters.getMenu.filter(m => m.bottom).sort((a, b) => a.bottom - b.bottom))
const link = computed(() => props.link)

// watch(link, console.log)

// const val = computed(null)

// watch(() => route.fullPath, (val) => {
// 	console.log( val )
// })

</script>