<template>
  <v-dialog v-model="show" :max-width="options.width" :style="{ zIndex: options.zIndex }">
    <v-form ref="form" v-hotkey="keymap">
      <v-card>
        <v-toolbar :color="options.color" dark dense flat>
          <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text v-show="!!message" class="pa-4">{{ message }}</v-card-text>
        <v-textarea class="mx-2" placeholder="Escribe aquí el motivo..." :rules="[req]" auto-grow rows="1" no-resize
          v-model="explanation" filled v-if="options.addExplanation" />
        
        <v-text-field v-if="mail=='true'" placeholder="Email para enviar copia" v-model="email_escrito" />
        
        <v-divider class="my-1"></v-divider>
        <v-card-actions class="pt-0 mt-2">
          <v-spacer></v-spacer>
          <v-btn @click.native="cancel" :color="options.cancelColor" text>Cancelar</v-btn>
          <v-btn @click.native="agree" :color="options.confirmColor" dark>{{ options.confirmText }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { req } from '@/utils/validations.js'

export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    mail: null,
    explanation: null,
    email_escrito: null,
    options: {
      color: 'primary',
      width: 400,
      zIndex: 200,
      cancelColor: 'grey',
      confirmColor: 'primary',
      addExplanation: false,
      confirmText: 'Confirmar'
    },
    req
  }),
  computed: {
    show: {
      get() {
        return this.dialog
      },
      set(value) {
        this.dialog = value
        if (value === false) {
          this.cancel()
        }
      }
    },
    keymap() {
      return {
        'enter': this.agree,
        'esc': this.cancel
      }
    }
  },
  methods: {
    open(title, message, mail, options) {
      this.dialog = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)
      this.mail = mail
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      if (!this.$refs.form.validate()) return;
      this.resolve({
        explanation: this.explanation || '',
        email: this.email_escrito
        
      });
      this.dialog = false;
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>