import Vue from 'vue'
import Vuex from "vuex";

import auth from '../modules/auth';
import home from '../modules/home';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth: auth.store,
    store: home.store,
  }
})

export default store